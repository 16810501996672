import { Cookies } from "./cookie.js";
/**
 * Returns the type of device based on the screen width.
 * @returns {string} The type of device: "mobi" for mobile, "tablet" for tablet, "desk" for desktop.
 */
export const getDevice = () => {
  const screenWidth = screen.width;
  if (screenWidth < 768) {
    return "mobi";
  } else if (screenWidth >= 768 && screenWidth <= 1024) {
    return "tablet";
  } else {
    return "desk";
  }
};

/**
 * Creates a default CBD (Citizens Bank Digital) object.
 * @returns {Object} The default CBD object.
 */
export const createCBDLObject = (customData) => {
  return {
    pgInfo: {
      cbcountry: "us",
      cblanguage: "eng",
      cbwebidentifier: "marketing",
      cbdevice: customData && customData.cbdevice ? customData.cbdevice : "",
      cbproduct: "wealth",
      cbtype: "consumer",
      cbpgType: "privateclientmanager",
      cbpgFunction:
        customData && customData.cbpgFunction ? customData.cbpgFunction : "",
      cbpgSubFunction:
        customData && customData.cbpgSubFunction
          ? customData.cbpgSubFunction
          : "",
      cbpgName: "view",
      cbaction: "view",
      cbcity: customData && customData.cbcity ? customData.cbcity : "",
      cbinvestableassets:
        customData && customData.cbinvestableassets
          ? customData.cbinvestableassets
          : "",
      cbstate: customData && customData.cbstate ? customData.cbstate : "",
      cbbrand: "cb",
      cbentityid:
        customData && customData.cbentityid ? customData.cbentityid : "",
    },
  };
};

/**
 * Gets the current position of the device.
 * @returns {string} The latitude and longitude coordinates in the format "latitude,longitude".
 */
export const getCurrentPosition = () => {
  navigator.geolocation.getCurrentPosition((position) => {
    try {
      return position.coords.latitude + "," + position.coords.longitude;
    } catch (err) {
      return "";
    }
  });
};

export const track = (additionalVariables = {}, pageReload = false) => {
  const device = getDevice();
  const myPosition = getCurrentPosition();

  let currSession = Cookies.getCookie("current_session");
  if (!currSession) {
    const sessionId = Math.floor(Date.now() * Math.random() * 100); // generate a unique session id
    Cookies.setCookie("current_session", sessionId, 1 / 48); // expire in half an hour
    currSession = sessionId;
  }
  let CBDL = localStorage.getItem("CBDL");
  if (CBDL) {
    CBDL = JSON.parse(CBDL);
  }
  const savedPageInfo = CBDL && CBDL.pgInfo ? CBDL.pgInfo : {};

  window.CBDL = createCBDLObject({
    ...savedPageInfo,
    cbdevice: device || "",
    cbgeoLocation: myPosition || "",
    cbsessionid: currSession || "",
    ...additionalVariables,
  });

  localStorage.setItem("CBDL", JSON.stringify(window.CBDL));

  if (pageReload && window._satellite) {
    _satellite.track("Advisor_CBPageload");
  }
  return JSON.stringify(window.CBDL);
};
